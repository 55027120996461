<template>
  <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/resume">Resume</router-link>
  </nav>
  <router-view class="p-3" />
  <footer class="p-3">
    <h3>Social & other sites</h3>
    <div class="row">
      <div class="col-sm">
        <a href="https://www.facebook.com/R.JayRich/">
          <img
            src="@/assets/icons/Facebook_icon.png"
            alt="Jay's Facebook page"
            target="_blank"
            class="py-2"
          />
        </a>
      </div>
      <div class="col-sm">
        <a href="https://www.instagram.com/rjayrich/">
          <img
            src="@/assets/icons/instagram_icon.png"
            alt="Jay's instagram page"
            target="_blank"
            class="py-2"
          />
        </a>
      </div>
      <div class="col-sm">
        <a href="https://twitter.com/JayMade">
          <img
            src="@/assets/icons/twitter_icon.png"
            alt="Jay's twitter page"
            target="_blank"
            class="py-2"
          />
        </a>
      </div>

      <div class="col-sm">
        <a href="https://www.flickr.com/photos/jaymade/albums">
          <img
            src="@/assets/icons/flickr_icon.png"
            alt="Jay's Flickr roll"
            target="_blank"
            class="py-2"
          />
        </a>
      </div>
      <div class="col-sm">
        <a href="http://jaymade.com">
          <img
            src="@/assets/icons/jayMade_icon.png"
            alt="jayMade Website"
            target="_blank"
            class="py-2"
          />
        </a>
      </div>
    </div>
  </footer>
</template>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body {
  min-height: 100vh;
  /* Four background images */
  background-image: url(@/assets/BG_corners/Corner_TL.png),
    url(@/assets/BG_corners/Corner_TR.png);
  /*Their positions*/
  background-position: top left, top right;
  /* These apply to all images above */
  background-repeat: no-repeat;
  // background-attachment: fixed;
}
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #33516f;

    &.router-link-exact-active {
      color: #e08f03;
    }
  }
}
footer {
  color: #727171;
  background-color: #000;
  margin: 0rem;
  padding: 0rem;
  min-height: 100px;
  h3 {
    padding-top: 1rem;
  }
}
</style>
